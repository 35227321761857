import dynamic from 'next/dynamic'
import { Poppins } from 'next/font/google'
import { Navbar } from './Navbar'

// Lazy load components
const Footer = dynamic(() => import('./Footer').then(mod => mod.Footer), { ssr: false })
const GoUpButton = dynamic(() => import('./GoUpButton').then(mod => mod.GoUpButton), { ssr: false })

const poppins = Poppins({
  subsets: ['latin'],
  weight: "400",
  display: "swap",
  variable: "--font-poppins",
})

const Layout = ({ children }) => {
  return (
    <div className={`${poppins.variable}`}>
      <Navbar />
      <div className="mt-[5rem]">
        {children}
      </div>
      <Footer />
      <GoUpButton />
    </div>
  )
}

export default Layout