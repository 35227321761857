/* eslint-disable @typescript-eslint/no-var-requires */
const path = require("path")

module.exports = {
    i18n: {
      defaultLocale: 'it',
      locales: ['it', 'en'],
      localeDetection: false
    },
    react: { useSuspense: false },
    localePath: path.resolve("./public/locales")
  }