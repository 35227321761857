import { gql } from '@apollo/client'

export const GET_COUNTRIES = gql`
  query GetCountries($locale: I18NLocaleCode!, $countryName: String, $limit: Int) {
    countries(locale: $locale, filters: {name: {eq: $countryName}}, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          name
          description
          shortDescription
          isoCode
          highlighted
          countryCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          cities {
            data {
              id
              attributes {
                name
              }
            }
          }
          continent {
            data {
              id
              attributes {
                name
              }
            }
          }
          locale
          localizations {
            data {
              attributes {
                name
                locale
              }
            }
          }
        }
      }
      meta {
        pagination {
          page,
          pageSize,
          pageCount
        }
      }
    }
  }`

export const GET_CITIES = gql`
  query GetCities($locale: I18NLocaleCode!, $countryName: String, $cityName: String, $pagination: Int){
    cities(locale: $locale, filters: {country: {name: {eq: $countryName}}, name: {eq: $cityName}}, pagination: { page: $pagination, pageSize: 8 }) {
      data {
        id
        attributes {
          name
          description
          cityCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
          locale
          localizations {
            data {
              attributes {
                name
                locale
              }
            }
          }
        }
      }
      meta {
        pagination {
          page,
          pageSize,
          pageCount
        }
      }
    }
  }`

export const GET_LATEST_POSTS = gql`
  query GetLatestPosts($locale: I18NLocaleCode!, $limit: Int!) {
    posts(sort: "publishedAt:desc", pagination: { limit: $limit }, locale: $locale, filters: {typology: {ne: "discount"}}) {
      data {
        id
        attributes {
          postTitle
          postDescription
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          postContent
          slug
          createdAt
          updatedAt
          publishedAt
          cities {
            data {
              id
              attributes {
                name
              }
            }
          }
          countries {
            data {
              id
              attributes {
                name
              }
            }
          }
          author {
            data {
              id
            }
          }
          typology
          likes
          locale
        }
      }
    }
  }`

export const GET_MOST_POPULAR_POSTS = gql`
  query GetMostPopularPosts($locale: I18NLocaleCode!, $limit: Int!) {
    posts(sort: "likes:desc", pagination: { limit: $limit }, locale: $locale, filters: {typology: {ne: "discount"}}) {
      data {
        id
        attributes {
          postTitle
          postDescription
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          postContent
          slug
          createdAt
          updatedAt
          publishedAt
          cities {
            data {
              id
              attributes {
                name
              }
            }
          }
          countries {
            data {
              id
              attributes {
                name
              }
            }
          }
          typology
          likes
          locale
        }
      }
    }
  }`

  export const GET_ADVERTISEMENT_POSTS = gql`
  query GetMostPopularPosts($locale: I18NLocaleCode!, $limit: Int!) {
    posts(pagination: { limit: $limit }, locale: $locale, filters: {typology: {eq: "discount"}}) {
      data {
        id
        attributes {
          postTitle
          postDescription
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          slug
          createdAt
          updatedAt
          publishedAt
          typology
          likes
          locale
        }
      }
    }
  }`

export const GET_POSTS = gql`
  query GetPosts($locale: I18NLocaleCode, $pagination: Int, $type: String, $id: ID, $city: String, $country: String, $continent: String, $slug: String) {
    posts(locale: $locale, filters: {typology: {eq: $type}, id: {eq: $id}, cities: {name: {eq: $city}}, countries: {name: {eq: $country}}, continents: {name: {eq: $continent}}, slug: {eq: $slug}}, pagination: { page: $pagination, pageSize: 8 }) {
      data {
        id
        attributes {
          postTitle
          postDescription
          seoDescription
          seoKeywords
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          postContent
          slug
          createdAt
          updatedAt
          publishedAt
          cities {
            data {
              id
              attributes {
                name
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                cityCover {
                  data {
                    id
                    attributes {
                      name
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
          countries {
            data {
              id
              attributes {
                name
                countryCover {
                  data {
                    id
                    attributes {
                      name
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
          continents {
            data {
              id
              attributes {
                name
              }
            }
          }
          author {
            data {
              id
            }
          }
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          typology
          locale
          localizations {
            data {
              attributes {
                slug
                locale
              }
            }
          }
        }
      }
      meta {
        pagination {
          page,
          pageSize,
          pageCount
        }
      }
    }
  }`

export const GET_AUTHOR = gql`
  query GetAuthor($locale: I18NLocaleCode!, $name: String, $surname: String) {
    authorsinfo(locale: $locale, filters: {name: {eq: $name}, surname: {eq: $surname}}) {
      data {
        id
        attributes {
          name
          surname
          shortDescription
          longDescription
          about
          picture {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          locale
        }
      }
    }
  }`

export const GET_BOOKMARKS_POSTS = gql`
  query GetBookmarksPosts($locale: I18NLocaleCode!, $ids: [ID]) {
    posts(locale: $locale, filters: {id: {in: $ids}}) {
      data {
        id
        attributes {
          postTitle
          postDescription
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          postContent
          slug
          createdAt
          updatedAt
          publishedAt
          cities {
            data {
              id
              attributes {
                name
              }
            }
          }
          countries {
            data {
              id
              attributes {
                name
              }
            }
          }
          author {
            data {
              id
            }
          }
          typology
          likes
          locale
        }
      }
    }
  }`

export const GET_POSTS_BY_TAG = gql`
  query GetPostsByTag($locale: I18NLocaleCode, $tag: String!) {
    posts(locale: $locale, filters: {tags: {name: {eq: $tag}}}, pagination: { page: 0, pageSize: 8 }) {
      data {
        id
        attributes {
          postTitle
          postDescription
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          postContent
          slug
          createdAt
          updatedAt
          publishedAt
          cities {
            data {
              id
              attributes {
                name
              }
            }
          }
          countries {
            data {
              id
              attributes {
                name
              }
            }
          }
          typology
          likes
          locale
        }
      }
      meta {
        pagination {
          page,
          pageSize,
          pageCount
        }
      }
    }
  }`

const GET_MOST_COMMON_TAGS = gql`
  query GetMostCommonTags($locale: I18NLocaleCode!, $limit: Int!) {
    tags(locale: $locale, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }`

export const GET_DISCLAIMER = gql`
  query GetDisclaimer($locale: I18NLocaleCode!) {
    disclaimer(locale: $locale) {
      data {
        id
        attributes {
          text
          description
          locale
        }
      }
    }
  }`

export const GET_CONTACTS = gql`
  query GetContacts($locale: I18NLocaleCode!) {
    contact(locale: $locale) {
      data {
        id
        attributes {
          text
          locale
        }
      }
    }
  }`

export const GET_PRIVACY_POLICY = gql`
  query GetPrivacyPolicy($locale: I18NLocaleCode!) {
    privacypolicy(locale: $locale) {
      data {
        id
        attributes {
          text
          locale
        }
      }
    }
  }`

export const GET_COOKIE_POLICY = gql`
  query GetCookiePolicy($locale: I18NLocaleCode!) {
    cookiepolicy(locale: $locale) {
      data {
        id
        attributes {
          text
          locale
        }
      }
    }
  }`

const MODIFY_POST_LIKES = gql`
  mutation modifyLikes($postID: ID!, $likes: Int!) {
    updatePost(id: $postID, data: {likes: $likes}) {
      data {
        id
        attributes {
          likes
          slug
        }
      }
    }
  }`

export const GET_POST_LIKES = gql`
  query GetPostLikes($postID: ID!) {
    posts(filters: { id: { eq: $postID } }) {
      data {
        id
        attributes {
          likes
          slug
        }
      }
    }
  }`

export const addLikeToPost = async ({ postID }, client: any) => {
  try {
    // Fetch current number of likes
    const { data } = await client.query({
      query: GET_POST_LIKES,
      variables: { "postID": postID },
    })

    // Increment likes by 1
    const currentLikes = data?.posts?.data?.[0]?.attributes?.likes
    const newLikes = currentLikes + 1

    // Execute mutation to update likes
    const response = await client.mutate({
      mutation: MODIFY_POST_LIKES,
      variables: { "postID": postID, "likes": newLikes },
    })

    return response
  } catch (error) {
    console.error('Error while updating post: ', error)
    throw error
  }
}

export const removeLikeFromPost = async ({ postID }, client: any) => {
  try {
    // Fetch current number of likes
    const { data } = await client.query({
      query: GET_POST_LIKES,
      variables: { "postID": postID },
    })

    // Decrement likes by 1
    const currentLikes = data?.posts?.data?.[0]?.attributes?.likes
    const newLikes = currentLikes - 1

    // Execute mutation to update likes
    const response = await client.mutate({
      mutation: MODIFY_POST_LIKES,
      variables: { "postID": postID, "likes": newLikes },
    })

    return response
  } catch (error) {
    console.error('Error while updating post: ', error)
    throw error
  }
}

export const GET_POSTS_SLUG = gql`
  query GetPostsSlug($locale: I18NLocaleCode!) {
    posts(locale: $locale, pagination: { limit: 1000 }) {
      data {
        id
        attributes {
          slug
          updatedAt
          locale
          localizations {
            data {
              attributes {
                slug
                locale
              }
            }
          }
        }
      }
    }
  }`

export const GET_CITIES_SUMMARY = gql`
  query GetCities($locale: I18NLocaleCode!) {
    cities(locale: $locale, pagination: { limit: 1000 }) {
      data {
        id
        attributes {
          name
          updatedAt
          locale
          localizations {
            data {
              attributes {
                name
                locale
              }
            }
          }
        }
      }
    }
  }`

export const  GET_COUNTRIES_SUMMARY = gql`
  query GetCountries($locale: I18NLocaleCode!, $highlighted: Boolean) {
    countries(locale: $locale, pagination: { limit: 1000 }, filters: {highlighted: {eq: $highlighted}}) {
      data {
        id
        attributes {
          name
          updatedAt
          isoCode
          locale
          localizations {
            data {
              attributes {
                name
                locale
              }
            }
          }
        }
      }
    }
  }`

  export const GET_POSTS_SUMMARY = gql`
  query GetPostsSummary($locale: I18NLocaleCode) {
    posts(locale: $locale, pagination: { limit: 500 }) {
      data {
        id
        attributes {
          postTitle
          postDescription
          seoDescription
          seoKeywords
          postCover {
            data {
              id
              attributes {
                name
                url
                formats
              }
            }
          }
          slug
          createdAt
          updatedAt
          publishedAt
          cities {
            data {
              id
              attributes {
                name
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                cityCover {
                  data {
                    id
                    attributes {
                      name
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
          countries {
            data {
              id
              attributes {
                name
                countryCover {
                  data {
                    id
                    attributes {
                      name
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
          continents {
            data {
              id
              attributes {
                name
              }
            }
          }
          typology
          locale
          localizations {
            data {
              attributes {
                slug
                locale
              }
            }
          }
        }
      }
    }
  }`

export const ADD_COMMENT = gql`
  mutation createComment($relation: String!, $content: String!, $authorName: String!, $authorEmail: String!, $authorID: Id!, $threadOf: Id) {
    createComment(
      input: {
        relation: $relation
        content: $content
        threadOf: $threadOf
        author: { id: $authorID, name: $authorName, email: $authorEmail }
      }
    ) {
      id
      content
      updatedAt
      threadOf {
        id
      }
      author {
        id
        name
      }
    }
  }`

export const DELETE_COMMENT = gql`
  mutation removeComment($relation: String!, $id: Id!, $authorID: Id!) {
    removeComment(
      input: {
        id: $id
        relation: $relation
        author: { id: $authorID }
      }
    ) {
      id
      removed
    }
  }`

export const GET_COMMENTS = gql`
  query GetComments($relation: String!) {
    findAllInHierarchy(relation: $relation, sort: "updatedAt:desc") {
      id
      content
      blocked
      approvalStatus
      updatedAt
      children {
        id
        content
        updatedAt
        blocked
        author {
          id
          name
        }
        threadOf {
          id
        }
        children {
          id
          content
          updatedAt
          blocked
          author {
            id
            name
          }
          threadOf {
            id
          }
        }
      }
      threadOf {
        id
      }
      author {
        id
        name
      }
    }
  }
`
