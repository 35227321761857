import React, { useEffect, useState } from "react"
import { Dialog, Disclosure, Popover } from "@headlessui/react"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { GET_COUNTRIES } from "../assets/api/apiCalls"
import { Country } from "../shared/types"
import { useQuery } from "@apollo/client"
import dynamic from "next/dynamic"
import { getCountryIcon } from "../shared/utils"
import Image from "next/image"
import { Icon } from '@iconify/react'

const SearchMenuModal = dynamic(() => import('./SearchMenuModal').then(mod => mod.MenuModal), { ssr: false })

export function Navbar() {
    const router = useRouter()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [showSearchMenuModal, setShowSearchMenuModal] = useState(false)
    const [showDestinationPanel, setShowDestinationPanel] = useState(false)
    const [showExplorePanel, setShowExplorePanel] = useState(false)
    const [showAboutPanel, setShowAboutPanel] = useState(false)
    const [showLanguagePanel, setShowLanguagePanel] = useState(false)
    const locale = router.locale
    const [selectedLanguage, setSelectedLanguage] = useState(locale)
    const { t } = useTranslation()

    const languages = [
        { name: "Italiano", code: "it" },
        { name: "English", code: "en" },
    ]

    const exploreTopics = [
        { name: t("common.itineraries"), icon: "mdi:map-marker-outline", link: "/itineraries" },
        { name: t("common.travel_advices"), icon: "mdi:airplane", link: "/traveladvices" },
        { name: t("common.discounts"), icon: "mdi:discount-outline", link: "/discounts" }
    ]

    const aboutSections = [
        { name: t("common.whoami"), icon: "hugeicons:shaka-04", link: "/about" },
        { name: t("common.contacts"), icon: "mdi:email-outline", link: "/contacts" },
    ]
    
    const callsToAction = [
        { name: t("destination.all_destinations"), href: "/destinations", icon: "mdi:globe" },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ")
    }

    /*useEffect(() => {
        const cookies = parseCookies()
        const savedLanguage = cookies.selectedLanguage
        const systemLanguage = navigator.language.split('-')[0]

        setSelectedLanguage(savedLanguage ? savedLanguage : systemLanguage)
        handleLanguageChange(savedLanguage ? savedLanguage : systemLanguage)
    }, [])*/

    useEffect(() => {
        setSelectedLanguage(locale)
    }, [locale])

    const { loading: countriesLoading, error: countriesError, data: countriesData } = useQuery(GET_COUNTRIES, {
        nextFetchPolicy: 'cache-first',
        variables: {
            "locale": locale
        },
        ssr: true
    })

    const handleLanguageChange = (languageCode: string) => {
        setSelectedLanguage(languageCode)
        setMobileMenuOpen(false)
    }

    const showFlag = (languageCode: string) => {
        const language = languages.find(language => language.code === languageCode)
        return <img className="h-5 w-8 drop-shadow-xl" src={`/assets/${language.code}-flag.webp`} alt={`${language.name}-flag`} />
    }

    return (
        <header id="header" className="bg-white font-poppins fixed top-0 left-0 right-0 z-50">
            <nav className="mx-auto flex flex-row max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex order-2 lg:order-1 lg:flex-1">
                    <Link href="/" className="-mt-1.5 -mb-1.5 pt-1.5 pb-1.5">
                        <span className="sr-only">Explorer's tale</span>
                        <img className="h-7 w-auto sm:h-8" src="/assets/logo.jpg" alt="Logo" />
                    </Link>
                </div>
                <div className="flex order-3 lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        {mobileMenuOpen ? (
                            <Icon icon="mdi:close" className="h-6 w-6" aria-hidden="true" />
                        ) : (
                            <Icon icon="mdi:menu" className="h-6 w-6" aria-hidden="true" />
                        )}
                    </button>
                </div>
                <div>
                    <button className="flex order-1 lg:hidden" aria-label={t("search.search_placeholder")} onClick={() => setShowSearchMenuModal(true)}>
                        <Icon icon="mdi:search" className="h-5 w-5" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12 lg:order-2 items-center">
                    <button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
                        <Link
                            href={{ pathname: "/"}}
                            className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                        >
                            Homepage
                        </Link>
                    </button>
                    <Popover className="relative" onMouseEnter={() => setShowDestinationPanel(true)} onMouseLeave={() => setShowDestinationPanel(false)} onClick={() => setShowDestinationPanel(!showDestinationPanel)}>
                        <button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
                            {t("common.destinations")}
                            <Icon icon="mdi:chevron-down" className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </button>
                        {showDestinationPanel && (
                            <div className="absolute -left-8 top-full z-10 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-4">
                                    {countriesData?.countries?.data?.filter((country: Country) => country.attributes.highlighted === true).map((country: Country) => (
                                        <div
                                            key={country.id}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-primary"
                                        >
                                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <div className="relative h-8 w-8">
                                                <Image
                                                    src={getCountryIcon(country.attributes.isoCode)}
                                                    alt="Logo"
                                                    fill={true}
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </div>
                                            </div>
                                            <div className="flex-auto">
                                                <Link
                                                    href={{ pathname: "/country/[name]", query: { name: country.attributes.name.toLowerCase()} }}
                                                    className="block font-semibold text-gray-900"
                                                >
                                                    {country.attributes.name.charAt(0).toUpperCase() + country.attributes.name.slice(1).toLowerCase()}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                                <p className="mt-1 text-gray-600">
                                                    {country.attributes.shortDescription}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="grid divide-x divide-gray-900/5 bg-primary rounded-xl mt-1">
                                        {callsToAction.map((item) => (
                                            <Link
                                                key={item.name}
                                                href={item.href}
                                                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-white font-semibold"
                                            >
                                                <Icon icon={item.icon} className="h-5 w-5 flex-none text-white animate-pulse" aria-hidden="true" />
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popover>
                    <Popover.Group className="hidden lg:flex lg:gap-x-12 lg:order-3 items-center">
                        <Popover className="relative" onMouseEnter={() => setShowExplorePanel(true)} onMouseLeave={() => setShowExplorePanel(false)} onClick={() => setShowExplorePanel(!showExplorePanel)}>
                            <button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
                                {t("common.explore")}
                                <Icon icon="mdi:chevron-down"
                                    className="h-5 w-5 flex-none text-gray-400"
                                    aria-hidden="true"
                                />
                            </button>
                            {showExplorePanel && (
                                <div className="absolute -left-8 top-full z-10 w-screen max-w-[20rem] overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        {exploreTopics.map((exploreTopic) => (
                                            <div
                                                key={exploreTopic.name}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-primary"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 text-xl group-hover:bg-white">
                                                    <Icon icon={exploreTopic.icon} className="h-5 w-5 flex-none text-gray-900" aria-hidden="true" />
                                                </div>
                                                <div className="flex-auto">
                                                    <Link
                                                        href={{ pathname: exploreTopic.link }}
                                                        className="block font-semibold text-gray-900"
                                                    >
                                                        {exploreTopic.name}
                                                        <span className="absolute inset-0" />
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Popover>
                    </Popover.Group>
                    <Popover.Group className="hidden lg:flex lg:gap-x-12 lg:order-3 items-center">
                        <Popover className="relative" onMouseEnter={() => setShowAboutPanel(true)} onMouseLeave={() => setShowAboutPanel(false)} onClick={() => setShowAboutPanel(!showAboutPanel)}>
                            <button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
                                {t("common.about")}
                                <Icon icon="mdi:chevron-down"
                                    className="h-5 w-5 flex-none text-gray-400"
                                    aria-hidden="true"
                                />
                            </button>
                            {showAboutPanel && (
                                <div className="absolute -left-20 top-full z-10 w-screen max-w-[20rem] overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        {aboutSections.map((aboutSection) => (
                                            <div
                                                key={aboutSection.name}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-primary"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 text-xl group-hover:bg-white">
                                                    <Icon icon={aboutSection.icon} className="h-5 w-5 flex-none text-gray-900" aria-hidden="true" />
                                                </div>
                                                <div className="flex-auto">
                                                    <Link
                                                        href={{ pathname: aboutSection.link }}
                                                        className="block font-semibold text-gray-900"
                                                    >
                                                        {aboutSection.name}
                                                        <span className="absolute inset-0" />
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Popover>
                    </Popover.Group>
                    <button onClick={() => setShowSearchMenuModal(true)} className="lg:order-5" aria-label={t("search.search_placeholder")}>
                        <Icon icon="mdi:search" className="h-5 w-5" />
                    </button>
                    <div className="flex order-4 lg:order-6 lg:flex-1 justify-end items-center">
                        <Popover className="relative">
                            <div onMouseEnter={() => setShowLanguagePanel(true)} onMouseLeave={() => setShowLanguagePanel(false)} onClick={() => setShowLanguagePanel(!showLanguagePanel)}>
                                <button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 cursor-pointer" aria-label={t("common.select_language")}>
                                    {showFlag(selectedLanguage)}
                                    <Icon icon="mdi:chevron-down" className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                </button>
                                {showLanguagePanel && (
                                    <div className="absolute -left-[4rem] top-full z-10 w-[8rem] p-3 overflow-hidden rounded-3xl bg-white shadow-lg rounded-xl">
                                        <div className="flex flex-col">
                                            {languages.filter((language) => language.code !== locale).map((language) => (
                                                <Link
                                                    key={language.code}
                                                    href={{
                                                        pathname: router.pathname,
                                                        query: router.query
                                                    }}
                                                    as={router.asPath}
                                                    locale={language.code}
                                                    onClick={() => handleLanguageChange(language.code)}
                                                >
                                                    <div className="flex flex-row justify-left items-center gap-2 cursor-pointer">
                                                        <img className="h-5 w-8 drop-shadow-xl" src={`/assets/${language.code}-flag.webp`} alt={`${language.name}-flag`} />
                                                        <span className="font-bold text-black">{language.name}</span>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Popover>
                    </div>
                </Popover.Group>
            </nav>
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <button className="flex order-1" onClick={() => {setShowSearchMenuModal(true); setMobileMenuOpen(false)}}>
                            <Icon icon="mdi:search" className="h-5 w-5" />
                        </button>
                        <Link href="/" className="flex order-2 -m-1.5 p-1.5">
                            <span className="sr-only">Explorer's Tale</span>
                            <img
                                className="h-8 w-auto"
                                src="/assets/logo.jpg"
                                alt="Logo"
                            />
                        </Link>
                        <button
                            type="button"
                            className="flex order-3 -m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <Icon icon="mdi:close" className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex flex-col justify-between h-[95%] gap-5">
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    <div className="-mx-3 flex w-full items-center justify-between rounded-lg py-2 pl-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                        <Link href="/" className="text-base text-gray-900" onClick={() => setMobileMenuOpen(false)}>
                                            Homepage
                                        </Link>
                                    </div>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    {t("common.destinations")}
                                                    <Icon icon="mdi:chevron-down"
                                                        className={classNames(
                                                            open
                                                                ? "rotate-180"
                                                                : "",
                                                            "h-5 w-5 flex-none",
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[
                                                        ...countriesData?.countries?.data || [],
                                                    ].filter((country: Country) => country.attributes.highlighted === true).map((country: Country) => (
                                                        <Disclosure.Button
                                                            key={country.id}
                                                            as={Link}
                                                            href={{ pathname: "/country/[name]", query: { name: country.attributes.name.toLowerCase()} }}
                                                            className="flex text-center items-center gap-3 block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                            onClick={() => setMobileMenuOpen(false)}
                                                        >
                                                            <img
                                                                className="h-6 w-6 text-gray-600"
                                                                src={getCountryIcon(country.attributes.isoCode)}
                                                            />
                                                            {country.attributes.name.charAt(0).toUpperCase() + country.attributes.name.slice(1).toLowerCase()}
                                                        </Disclosure.Button>
                                                    ))}
                                                    {[
                                                        ...callsToAction,
                                                    ].map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as={Link}
                                                            href={item.href}
                                                            className="flex flex-row gap-3 items-center rounded-lg bg-primary py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white text-semibold"
                                                            onClick={() => setMobileMenuOpen(false)}
                                                        >   
                                                            <Icon icon={item.icon} className="h-5 w-5 flex-none text-white" aria-hidden="true" />
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    {t("common.explore")}
                                                    <Icon icon="mdi:chevron-down"
                                                        className={classNames(
                                                            open
                                                                ? "rotate-180"
                                                                : "",
                                                            "h-5 w-5 flex-none",
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[
                                                        ...exploreTopics,
                                                    ].map((exploreTopic) => (
                                                        <Disclosure.Button
                                                            key={exploreTopic.name}
                                                            as={Link}
                                                            href={{ pathname: exploreTopic.link }}
                                                            className="flex text-center items-center gap-3 block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                            onClick={() => setMobileMenuOpen(false)}
                                                        >
                                                            <span className="text-xl">
                                                                <Icon icon={exploreTopic.icon} className="h-5 w-5 flex-none text-gray-600" aria-hidden="true" />
                                                            </span>
                                                            {exploreTopic.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    {t("common.about")}
                                                    <Icon icon="mdi:chevron-down"
                                                        className={classNames(
                                                            open
                                                                ? "rotate-180"
                                                                : "",
                                                            "h-5 w-5 flex-none",
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[
                                                        ...aboutSections,
                                                    ].map((aboutSection) => (
                                                        <Disclosure.Button
                                                            key={aboutSection.name}
                                                            as={Link}
                                                            href={{ pathname: aboutSection.link }}
                                                            className="flex text-center items-center gap-3 block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                            onClick={() => setMobileMenuOpen(false)}
                                                        >
                                                            <span className="text-xl">
                                                                <Icon icon={aboutSection.icon} className="h-5 w-5 flex-none text-gray-600" aria-hidden="true" />
                                                            </span>
                                                            {aboutSection.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mx-auto gap-5 items-center">
                            {languages.filter((language) => language.code !== locale).map((language) => (
                                <Link
                                    key={language.code}
                                    href={{
                                        pathname: router.pathname,
                                        query: router.query
                                    }}
                                    as={router.asPath}
                                    locale={language.code}
                                    onClick={() => handleLanguageChange(language.code)}
                                >
                                    <div className="flex flex-row justify-left items-center gap-2 cursor-pointer">
                                        <img className="h-5 w-8 drop-shadow-xl" src={`/assets/${language.code}-flag.webp`} alt={`${language.name}-flag`} />
                                        <span className="font-bold text-black">{language.name}</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
            {showSearchMenuModal && <SearchMenuModal showModal={showSearchMenuModal} setShowModal={setShowSearchMenuModal} />}
        </header>
    )
}
