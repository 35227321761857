import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer.js'
import tailwindConfig from '../../tailwind.config.js'
import { TailwindConfig } from '../shared/types'
import { format } from 'date-fns'

export const getImageFromStrapiUploads = (imagePath: any, format?: string) => {
    if (format === "small") {
        imagePath = imagePath?.formats?.small?.url
    } else if (format === "thumbnail") {
        imagePath = imagePath?.formats?.thumbnail?.url
    } else {
        imagePath = imagePath?.url
    }
    if (process.env.NEXT_PUBLIC_USE_INTERNAL_STORAGE === "true") {
        return process.env.NEXT_PUBLIC_BASE_URL + imagePath
    } else {
        return imagePath
    }
}

export const getRandomText = (texts: string[]) => {
    return texts[Math.floor(Math.random() * texts.length)]
}

export const getPrimaryColor = () => {
    const config: TailwindConfig = tailwindConfig as TailwindConfig
    let primaryColor: string = ""
    const primaryColorValue = config.theme.extend.colors.primary
    if (typeof primaryColorValue === 'string') {
        primaryColor = primaryColorValue;
    } else if (typeof primaryColorValue === 'object' && primaryColorValue !== null) {
        primaryColor = primaryColorValue['DEFAULT'] || ''
    }
    return primaryColor
}

export const getFlagFromCountryCode = (countryCode: string) => {
    if (countryCode === "en") {
        return "gb"
    } else if (countryCode === "it") {
        return "it"
    }
    return undefined
}

export const getLanguageNameFromLocale = (locale: string) => {
    if (locale === "en") {
        return "english"
    } else if (locale === "it") {
        return "italian"
    }
    return undefined
}

export const getCountryIcon = (countryCode: string) => {
    if (countryCode === "it") {
        return "/assets/italy-shape.png"
    } else if (countryCode === "es") {
        return "/assets/spain-shape.png"
    } else if (countryCode === "fr") {
        return "/assets/france-shape.png"
    } else if (countryCode === "gr") {
        return "/assets/greece-shape.png"
    } else if (countryCode === "pl") {
        return "/assets/poland-shape.png"
    } else if (countryCode === "tr") {
        return "/assets/turkey-shape.png"
    } else {
        return ""
    }
}

export const getPostTypology = (typology: string) => {
    if (typology === "destination") {
        return "travel_destination"
    } else if (typology === "itinerary") {
        return "travel_itinerary"
    } else if (typology === "travelAdvice") {
        return "travel_advice"
    } else if (typology === "discount") {
        return "advertisement"
    } else {
        "other"
    }
}

export const getPostTypologyhref = (typology: string) => {
    if (typology === "destination") {
        return "/destinations"
    } else if (typology === "itinerary") {
        return "/itineraries"
    } else if (typology === "travelAdvice") {
        return "/traveladvices"
    } else if (typology === "discount") {
        return "/discounts"
    } else {
        return "/"
    }
}

export const getArticleReadingTime = (postContent: RootNode[]) => {
    if (postContent !== undefined) {
        let readingTime = 0
        postContent.forEach((node) => {
            if (node.type === "paragraph") {
                readingTime += Math.ceil(node.children[0]['text'].split(" ").length / 200)
            }
        })
        return readingTime
    }
    return 0
}

export function formatDate(date: string) {
    return format(new Date(date), 'dd/MM/yyyy')
}

export function formatDateTime(date: string) {
    return format(new Date(date), 'dd/MM/yyyy HH:mm')
}