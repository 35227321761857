import { appWithTranslation } from 'next-i18next'
import '../styles/globals.css';
import Layout from '../components/Layout'
import { ApolloProvider } from '@apollo/client'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import Head from 'next/head'
import Script from 'next/script'
import nextI18nConfig from "../../next-i18next.config"

export const loadApolloClient = () => {
    return new ApolloClient({
        ssrMode: true,
        uri: process.env.NEXT_PUBLIC_BASE_URL + '/graphql',
        cache: new InMemoryCache({
            typePolicies: {
                Country: {
                    keyFields: ["name"],
                },
                Post: {
                    keyFields: ["slug"],
                },
                City: {
                    keyFields: ["name"],
                },
                Continent: {
                    keyFields: ["name"],
                },
                AuthorData: {
                    keyFields: ["name", "surname"],
                },
                UploadFile: {
                    keyFields: ["url"],
                }
            },
        }),
    })
}

const client = loadApolloClient()

export const getApolloClient = () => {
    return client
}

function App({ Component, pageProps }) {
    const client = getApolloClient()

    const getScripts = () => {
        return (
            <>  
                <Script id="consentMode" strategy="afterInteractive">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag() { dataLayer.push(arguments); }
                        gtag("consent", "default", {
                            ad_storage: "denied",
                            ad_user_data: "denied", 
                            ad_personalization: "denied",
                            analytics_storage: "denied",
                            functionality_storage: "denied",
                            personalization_storage: "denied",
                            security_storage: "granted",
                            wait_for_update: 2000,
                        });
                        gtag("set", "ads_data_redaction", true);
                        gtag("set", "url_passthrough", true);
                    `}
                </Script>
                <Script id="googleTagManager" strategy="afterInteractive">
                    {`
                        (function(w,d,s,l,i){
                            w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                            var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:''; j.async=true;
                            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl; f.parentNode.insertBefore(j,f);
                        })(window, document, 'script', 'dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
                    `}
                </Script>
                <Head>
                    <meta name="google-adsense-account" content={`${process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_ID}`}></meta>
                    <meta name="yandex-verification" content="1557cc40b9d77330" />
                </Head>
                <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_MEASUREMENT_ID}`} strategy="afterInteractive"/>
                <Script id="cookieyes" src={`https://cdn-cookieyes.com/client_data/${process.env.NEXT_PUBLIC_COOKIEYES_ID}/script.js`} strategy="afterInteractive"/>
                <Script id="googleanalytics" strategy="afterInteractive">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${process.env.NEXT_PUBLIC_MEASUREMENT_ID}');
                    `}
                </Script>
            </>
        )
    }

    return (
        <>
            {getScripts()}
            <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.ico"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.ico"/>
            </Head>
            <ApolloProvider client={client}>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </ApolloProvider>
        </>
    )
}

export default appWithTranslation(App, nextI18nConfig)