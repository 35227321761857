/** @type {import('tailwindcss').Config} */
module.exports = {
	content: [
		"./app/**/*.{js,ts,jsx,tsx,mdx}",
		"./pages/**/*.{js,ts,jsx,tsx,mdx}",
		"./components/**/*.{js,ts,jsx,tsx,mdx}",
	
		// Or if using `src` directory:
		"./src/**/*.{js,ts,jsx,tsx,mdx}",
	],
	theme: {
		extend: {
			padding: {
				"1/3": "33.333333%",
				"2/3": "66.666667%",
			},
			maxWidth: {
				"1/3": "33.333333%",
				"2/3": "66.666667%",
				"1/4": "25%",
				"1/2": "50%",
				"3/4": "75%",
			},
			screens: {
				'xs': '345px',
				'xl-m': '1460px',
			},
			colors: {
				"primary": "#2A7CBB",
				"secondary": "#37392E",
				"tertiary": "#19647e",
				"quaternary": "#DDCECD",
				"quinary": "#EEE5E5",
			},
			keyframes: {
				slidein: {
					from: {
						opacity: "0",
						transform: "translateY(-10px)",
					},
					to: {
						opacity: "1",
						transform: "translateY(0)",
					},
				},
				flyAway: {
					from: { transform: 'translateY(0px)' },
					to: { transform: 'translateY(-20px)', opacity: 0 },
				},
			},
			animation: {
				slidein: "slidein 1s ease 300ms",
				flyAway: 'flyAway 0.5s ease-in-out forwards'
			},
			fontFamily: {
				'poppins': ['var(--font-poppins)'],
			},
		},
	},
	plugins: [
		require('@tailwindcss/typography')
	],
}